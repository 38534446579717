import './styles.scss'

import React, { Fragment, Ref, useEffect } from 'react'
import Icon from '../Icons'

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  error?: string
  label?: string
  className?: string
  autoComplete?: string
  autoFocus?: boolean
  iconName?: string
  iconClassName?: string
  wrapperClassName?: string
  onEnter?: () => void
  disabled?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      error,
      name,
      type,
      label,
      className = '',
      iconName,
      iconClassName,
      wrapperClassName = '',
      autoComplete,
      autoFocus,
      disabled,
      ...props
    },
    ref: any,
  ) => {
    const onKeyDown = (ev) => {
      if (props?.onEnter && ev.key === 'Enter') {
        ev.preventDefault()
        props.onEnter()
      }
    }

    useEffect(() => {
      if (autoFocus) ref.current.focus()
    }, [autoFocus, ref])

    return (
      <Fragment>
        {label && (
          <label
            className={`caption mb-4 d-block font-semibold ${error ? 'text-error' : 'text-ultra-dark-grey'}`}
            htmlFor={name}>
            {label}
          </label>
        )}
        <div
          className={`${
            iconName
              ? 'golf-container__with-icon ' + iconClassName?.includes('is-left')
                ? 'has-left-icon'
                : 'has-right-icon'
              : ''
          } ${wrapperClassName}`}>
          <input
            {...props}
            ref={ref}
            type={type}
            name={name}
            onKeyDown={onKeyDown}
            className={`golf-input ${error ? 'is-error' : ''} ${className}`}
            autoComplete={autoComplete ?? ''}
            disabled={disabled}
          />
          {iconName && <Icon name={iconName} className={`golf-icon ${iconClassName ?? ''}`} />}
        </div>
        {error && <p className={`caption ${error ? 'text-error' : ''}`}>{error}</p>}
      </Fragment>
    )
  },
)

Input.displayName = 'Input'

export default Input
