import { ReactComponent as Arrow } from './arrow.svg'
import { ReactComponent as Bell } from './bell.svg'
import { ReactComponent as BoldPin } from './bold-pin.svg'
import { ReactComponent as Calendar } from './calendar.svg'
import { ReactComponent as Camera } from './camera.svg'
import { ReactComponent as Check } from './check.svg'
import { ReactComponent as CircledPlus } from './circled-plus.svg'
import { ReactComponent as Clock } from './clock.svg'
import { ReactComponent as Close } from './close.svg'
import { ReactComponent as Division } from './division.svg'
import { ReactComponent as Dollar } from './dollar.svg'
import { ReactComponent as DollarText } from './dollar-text.svg'
import { ReactComponent as Download } from './download.svg'
import { ReactComponent as External } from './external.svg'
import { ReactComponent as Facebook } from './facebook.svg'
import { ReactComponent as Filter } from './filter.svg'
import { ReactComponent as Caret } from './caret.svg'
import { ReactComponent as GolfFlag } from './golf-flag.svg'
import { ReactComponent as GolfFormat } from './golf-format.svg'
import { ReactComponent as Google } from './google.svg'
import { ReactComponent as HandicapIndex } from './handicap-index.svg'
import { ReactComponent as Heart } from './heart.svg'
import { ReactComponent as HeartFilled } from './heart-filled.svg'
import { ReactComponent as Info } from './info.svg'
import { ReactComponent as Leaderboard } from './leaderboard.svg'
import { ReactComponent as Link } from './link.svg'
import { ReactComponent as Linkedin } from './linkedin.svg'
import { ReactComponent as Locate } from './locate.svg'
import { ReactComponent as Map } from './map.svg'
import { ReactComponent as MapPin } from './map-pin.svg'
import { ReactComponent as MapPinFilled } from './map-pin-filled.svg'
import { ReactComponent as PlayVideo } from './play-video.svg'
import { ReactComponent as Price } from './price.svg'
import { ReactComponent as Print } from './print.svg'
import { ReactComponent as Scorecard } from './scorecard.svg'
import { ReactComponent as Search } from './search.svg'
import { ReactComponent as Share } from './share.svg'
import { ReactComponent as Twitter } from './twitter.svg'
import { ReactComponent as Users } from './users.svg'
import { ReactComponent as Warning } from './warning.svg'
import { ReactComponent as Grid } from './grid.svg'
import { ReactComponent as List } from './list.svg'
import { ReactComponent as SortBy } from './sort-by.svg'

export default {
  grid: Grid,
  list: List,
  caret: Caret,
  arrow: Arrow,
  bell: Bell,
  'bold-pin': BoldPin,
  'sort-by': SortBy,
  calendar: Calendar,
  camera: Camera,
  check: Check,
  'circled-plus': CircledPlus,
  clock: Clock,
  'close-icon': Close,
  division: Division,
  dollar: Dollar,
  'dollar-text': DollarText,
  download: Download,
  external: External,
  facebook: Facebook,
  filter: Filter,
  'golf-flag': GolfFlag,
  'golf-format': GolfFormat,
  google: Google,
  'handicap-index': HandicapIndex,
  heart: Heart,
  'heart-filled': HeartFilled,
  info: Info,
  leaderboard: Leaderboard,
  link: Link,
  linkedin: Linkedin,
  locate: Locate,
  map: Map,
  'map-pin': MapPin,
  'map-pin-filled': MapPinFilled,
  'play-video': PlayVideo,
  price: Price,
  print: Print,
  scorecard: Scorecard,
  search: Search,
  share: Share,
  twitter: Twitter,
  users: Users,
  warning: Warning,
}
