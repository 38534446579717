import 'keen-slider/keen-slider.min.css'
import React, { Fragment, useEffect, useState } from 'react'
import 'react-multi-carousel/lib/styles.css'
import { RectShape } from 'react-placeholder/lib/placeholders'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useCurrentWidth from 'src/hooks/useCurrentWidth'
import { Filter } from 'src/store/reducers/eventFilters'
import { EventFilters, EventType } from '../../Types/Event'
import { LocationsType } from '../../Types/Locations'
import StorySlider from '../Sliders/StorySlider'
import Navigation from './Navigation'
import Search from './Search'
import './styles.scss'
import Carousel from 'react-multi-carousel'

interface HeaderProps {
  title?: string
  backgroundImage?: string
  hasSearch?: boolean
  hasSlider?: boolean
  isNavTransparent?: boolean
  isTall?: boolean
  images?: Array<string>
  backgroundImages?: Array<string>
  event?: EventType
  currentLocation?: LocationsType
  className?: string
  placeholder?: boolean
  filter: (filters: Partial<EventFilters>) => void
  hasCtaGG?: boolean
  stayTransparentSticky?: boolean
  goBackToRoute?: string
  includeURLSearch?: boolean
}

const Header = (props: HeaderProps) => {
  let history = useHistory()
  const isEventPreview = ['preview-event', 'test', 'iframe'].some((route) => history.location.pathname.includes(route))

  const handleSearch = (value) => {
    props?.filter({ ...value })
    history.push('/events')
  }
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  let width = useCurrentWidth()

  const responsiveStyle = {
    desktop: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
    },
  }

  return (
    <Fragment>
      <header
        className={`golf-header ${!props.hasSlider && !props.isTall && !props.backgroundImage ? 'has-nav-only' : ''} ${
          props.isTall ? 'is-full-height' : ''
        } ${props.className ? props.className : ''}`}>
        {!isEventPreview && (
          <Navigation
            isTransparent={props.isNavTransparent}
            hasCtaGG={props.hasCtaGG}
            stayTransparentSticky={props.stayTransparentSticky}
            goBackToRoute={props.goBackToRoute ?? ''}
            includeURLSearch={props?.includeURLSearch}
          />
        )}
        <div className={'golf-container'}>
          {!props.hasSlider && props.backgroundImage && (
            <figure
              className={'golf-header__singleImage mt-0 sm_tablet:mt-24'}
              style={{ backgroundImage: `url(${props.backgroundImage})` }}></figure>
          )}
          {props.hasSlider && props?.images.length > 0 && (
            <>
              {!loading && (
                <StorySlider
                  images={props?.images}
                  className={'golf-header__storySlider mt-0 sm_tablet:mt-24'}
                  placeholder={props.placeholder}
                />
              )}
              {loading && (
                <RectShape
                  className="golf-header__storySlider mt-0 sm_tablet:mt-24"
                  color="#E0E0E0"
                  style={{
                    width: width < 768 ? '100vw' : '100%',
                    height: width < 1024 ? '200px' : '240px',
                    marginRight: 0,
                  }}
                />
              )}
            </>
          )}
          {props.backgroundImages && (
            <div className="absolute tw-top-0 tw-left-0 -tw-z-10">
              <Carousel
                arrows={false}
                responsive={responsiveStyle}
                autoPlay
                autoPlaySpeed={5000}
                className="w-screen h-screen"
                sliderClass="h-screen"
                customTransition="transform 1000ms ease-in-out"
                transitionDuration={1000}
                infinite>
                {props.backgroundImages.map((backgroundImage, index) => (
                  <img className="tw-object-cover w-screen h-screen" key={index} src={backgroundImage} />
                ))}
              </Carousel>
            </div>
          )}
        </div>
        {(props.title || props.hasSearch) && (
          <div className={'golf-container mt-auto'}>
            {props.title && <h1 className="mb-80 sm_tablet:mb-120 sm_desktop:mb-104">{props.title}</h1>}
            {props.hasSearch && <Search currentLocation={props.currentLocation} handleSearch={handleSearch} />}
          </div>
        )}
      </header>
    </Fragment>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    filter: (filters: Partial<EventFilters>) => dispatch(Filter(filters)),
  }
}

export default connect(null, mapDispatchToProps)(Header)
